<template>
  <div id="sw">
    <template v-for="(item, index) in pageContent">
      <component :is="item.elementName" :element-props="item.elementProperties" :key="index"></component>
    </template>
  </div>
</template>

<script>
export default {
  name: 'SimplePage',
  components: {
    //
  },
  mounted() {
    // console.log('simple page');
  },
  async beforeMount() {
    this.$store.commit('deleteFrontEndActualJob');
    const url = await this.UrlDecoder(this.$route.path);
    const simplePageFound = await this.GetPageContent(url);
    if (!simplePageFound) {
      const jobFound = await this.GetJobContent(url);
      if (!jobFound) {
        this.$router.push({ name: '404-oldal' });
      }
    }
  },
  props: {
    //
  },
  data() {
    return {
      pageContent: [],
      url: '',
    };
  },
  methods: {
    async initPage(pageUrl) {
      this.pageContent = [];
      this.$store.commit('deleteFrontEndActualJob');
      this.url = await this.UrlDecoder(pageUrl);
      const simplePageFound = await this.GetPageContent(this.url);
      if (!simplePageFound) {
        const jobFound = await this.GetJobContent(this.url);
        if (!jobFound) {
          this.$router.push({ name: '404-oldal' });
        }
      }
    },
    async UrlDecoder(url) {
      const pageUrl = url.replace('/', '');
      const lastChar = pageUrl.charAt(pageUrl.length - 1);
      let urlToSend = pageUrl;
      if (lastChar === '/') {
        urlToSend = pageUrl.substr(0, pageUrl.length - 1);
      }
      this.url = urlToSend;
      return urlToSend;
    },
    async GetPageContent(url) {
      const response = await this.$store.dispatch('showPageByUrl', url);
      if (response.status === 200) {
        this.pageContent = JSON.parse(response.data.contentJson);
        return true;
      }
      return false;
    },
    async GetJobContent(url) {
      const response = await this.$store.dispatch('showJobByUrl', url);
      if (response.status === 200) {
        this.pageContent = JSON.parse(response.data.contentJson);
        return true;
      }
      return false;
    },
  },
  watch: {
    $route(params) {
      this.initPage(params.path);
    },
  },
};
</script>

<style scoped>

</style>
